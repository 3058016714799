import { createWrapper } from "./AxiosWrapper";
import { PATH_SCOPE } from "../constants/Constants";

export async function fetchAccountParams(
  param: string,
  headers: any = {},
  option: any = {}
): Promise<any> {
  return createWrapper(option)
    .get(`${PATH_SCOPE}/api/account_params.json?${param}`, { headers })
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        accountParams: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errorMessages: error.response.data.error_messages,
      });
    });
}

export async function postAccountParams(param: any): Promise<any> {
  return createWrapper()
    .post(`${PATH_SCOPE}/api/account_params.json`, param)
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        accountParams: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errorMessages: error.response.data.error_messages,
      });
    });
}
