import { useEffect } from "react";
import { useRouter } from "next/router";
import { setCookie } from "main/javascripts/utils/cookieUtil";
import { UTM_COOKIE_EXPIRATION_DAYS } from "main/javascripts/constants/SearchConstants";
import { useAppDispatch } from "main/javascripts/store";
import { setIsTrackingParameterStored } from "main/javascripts/slices/paramSlice";

export const useTrackingParameter = () => {
  const router = useRouter();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (router.isReady) {
      appDispatch(setIsTrackingParameterStored(false));
      const query = router.query;
      const acType = query.ac_type;
      const utmSource = query.utm_source;
      const utmMedium = query.utm_medium;
      const utmCampaign = query.utm_campaign;
      const utmContent = query.utm_content;
      const utmTerm = query.utm_term;
      const tSessionId = query.t_session_id;
      const gclid = query.gclid;
      const wbraid = query.wbraid;
      const gbraid = query.gbraid;
      const yclid = query.yclid;

      if (acType) {
        setCookie("ac_type", acType, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmSource) {
        setCookie("utm_source", utmSource, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmMedium) {
        setCookie("utm_medium", utmMedium, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmCampaign) {
        setCookie("utm_campaign", utmCampaign, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmContent) {
        setCookie("utm_content", utmContent, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmTerm) {
        setCookie("utm_term", utmTerm, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (tSessionId) {
        setCookie("t_session_id", tSessionId, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (gclid) {
        setCookie("gclid", gclid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (wbraid) {
        setCookie("wbraid", wbraid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (gbraid) {
        setCookie("gbraid", gbraid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (yclid) {
        setCookie("yclid", yclid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      appDispatch(setIsTrackingParameterStored(true));
    }
  }, [router.isReady]);
  return;
};
